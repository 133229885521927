<template>
  <div>
    <div class="mb-50 d-flex">
      <b-form-select
        v-model="year"
        class="w-auto mr-50"
        :options="yearOptions"
      />
      <b-button
        v-for="trimester in trimesters"
        :key="`trimester-${trimester}`"
        size="sm"
        variant="light"
        class="py-0"
        :class="{ 'mr-50': trimester < trimesters }"
        @click="onTrimesterSelected(trimester)"
      >
        {{ trimester }}T
      </b-button>
    </div>
    <div class="d-flex">
      <flat-pickr
        v-model="reportDatesRangeInput"
        class="form-control"
        :config="rangepickerConfig"
        placeholder="Dates d'entrada"
        @on-change="onDatesRangeChanged"
      />
    </div>
  </div>
</template>

<script>
import { BButton, BFormSelect } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { Catalan } from "flatpickr/dist/l10n/cat.js";
import { formatDateObjectToDatabaseDate } from "@/utils/formatters";

export default {
  components: {
    BButton,
    BFormSelect,
    flatPickr,
  },
  props: {
    value: {
      type: Object,
      default: () => ({ from: null, to: null }),
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    trimesters: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
      reportDatesRangeInput: null,
      reportDatesRange: {
        from: null,
        to: null,
      },
      rangepickerConfig: {
        mode: "range",
        locale: Catalan,
        altFormat: "d/m/Y",
        altInput: true,
      },
    };
  },
  computed: {
    yearOptions() {
      const options = [];
      const currentYear = new Date().getFullYear();
      for (let index = 0; index < 5; index++) {
        const iterationYear = currentYear - index;
        options.push({
          value: iterationYear,
          text: `${iterationYear}`,
        });
      }
      return options;
    },
  },
  methods: {
    onDatesRangeChanged(selectedDates) {
      if (selectedDates?.length !== 2) return;
      this.reportDatesRange.from = formatDateObjectToDatabaseDate(
        selectedDates[0]
      );
      this.reportDatesRange.to = formatDateObjectToDatabaseDate(
        selectedDates[1]
      );
      this.$emit("input", this.reportDatesRange);
    },
    onTrimesterSelected(trimester) {
      if (!trimester) return;
      switch (trimester) {
        case 1:
          this.reportDatesRangeInput = `${this.year}-01-01 to ${this.year}-03-31`;
          break;
        case 2:
          this.reportDatesRangeInput = `${this.year}-04-01 to ${this.year}-06-30`;
          break;
        case 3:
          this.reportDatesRangeInput = `${this.year}-07-01 to ${this.year}-09-30`;
          break;
        case 4:
          this.reportDatesRangeInput = `${this.year}-10-01 to ${this.year}-12-31`;
          break;
        default:
          this.reportDatesRangeInput = null;
          break;
      }
    },
  },
};
</script>
