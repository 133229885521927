<template>
  <model-179-report />
</template>

<script>
import Model179Report from "@/views/administration/aeat-reports/179/Model179Report.vue";

export default {
  components: {
    Model179Report,
  },
};
</script>
