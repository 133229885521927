<template>
  <div>
    <b-card title="Model 179">
      <p class="mb-2">
        Declaració informativa trimestral de la cessió d'ús d'habitatges amb
        finalitats turístiques.
        <a
          href="https://sede.agenciatributaria.gob.es/Sede/ca_es/procedimientoini/GI44.shtml"
          target="_blank"
        >
          Més informació
        </a>
      </p>

      <!-- SELECT DATES -->
      <b-row class="my-3">
        <b-col cols="12">
          <h4>1. Selecciona les reserves a declarar</h4>
        </b-col>
        <b-col cols="12" class="mb-50 d-flex">
          <trimester-selector v-model="reportDatesRange" />
          <b-button
            variant="primary"
            class="ml-50"
            :disabled="!canSearch"
            @click="fetchUnsentAeat179Reports"
          >
            Cerca
          </b-button>
        </b-col>
      </b-row>

      <!-- VERIFY BOOKINGS -->
      <b-row class="my-3">
        <b-col cols="12">
          <h4>2. Verifica les reserves</h4>
        </b-col>
        <b-col cols="12">
          <b-overlay :show="loading" rounded="sm" variant="white" opacity="0.9">
            <b-alert v-if="!reports.length" variant="warning" :show="true">
              <div class="alert-body">
                Sense resultats. Selecciona les dates i clica al botó "Cerca"
              </div>
            </b-alert>

            <template v-if="hasReportsWithError">
              <!-- REPORTS WITH ERROR ALERT -->
              <b-alert variant="danger" :show="true">
                <div class="alert-body">
                  Hi ha {{ reportsWithError.length }} reserves sense dades
                  correctes del client. Cal corregir-ho abans de poder
                  descarregar el fitxer XML
                </div>
              </b-alert>

              <!-- REPORTS WITH ERROR TABLE -->
              <reports-with-error-table
                :loading="loading"
                :items="reportsWithError"
                @item-selected="onItemSelected"
              />

              <!-- ADD CLIENT DETAILS MODAL -->
              <b-modal
                id="add-client-details-modal"
                title="Actualitza les dades del client"
                centered
                hide-footer
                no-close-on-backdrop
              >
                <client-form
                  :client="selectedClient"
                  @saved="onClientSaved"
                  @error="onSaveClientError"
                />
              </b-modal>
            </template>

            <template v-if="reports.length && !hasReportsWithError">
              <b-alert variant="success" :show="true">
                <div class="alert-body">
                  Hi ha {{ reports.length }} reserves per notificar.
                  Descarrega't el fitxer XML i carrega'l a la AEAT.
                </div>
              </b-alert>

              <b-button class="mr-50" @click="generateReport">
                Descarrega
              </b-button>
            </template>
          </b-overlay>
        </b-col>
      </b-row>

      <!-- UPLOAD XML TO AEAT -->
      <b-row class="my-3">
        <b-col cols="12">
          <h4>3. Carrega el fitxer XML a la AEAT</h4>
        </b-col>
        <b-col cols="12">
          <b-button
            class="mr-50"
            href="https://www1.agenciatributaria.gob.es/static_files/common/internet/dep/explotacion/ws/ClienteWSAEATModelos.html"
            target="_blank"
          >
            Carrega
            <feather-icon icon="LinkIcon" class="ml-25" />
          </b-button>
        </b-col>
      </b-row>

      <!-- ANALIZE AEAT REPSONSE -->
      <b-row class="my-3">
        <b-col cols="12">
          <h4>4. Analitza el fitxer de resposta</h4>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BAlert,
  BOverlay,
  BModal,
} from "bootstrap-vue";
import TrimesterSelector from "@/views/administration/aeat-reports/179/components/TrimesterSelector.vue";
import ReportsWithErrorTable from "@/views/administration/aeat-reports/179/components/ReportsWithErrorTable.vue";
import ClientForm from "@/views/administration/aeat-reports/179/components/ClientForm.vue";
import { formatDateStringToAeatDate } from "@/utils/formatters";
import { notifyError } from "@/utils/methods";
import { saveAs } from "file-saver";
import {
  AGENCY_LEGAL_NAME,
  AGENCY_CIF,
  AGENCY_CONTACT_NAME,
  AGENCY_PHONE,
} from "@appConfig";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BAlert,
    TrimesterSelector,
    ReportsWithErrorTable,
    ClientForm,
    BModal,
  },
  data() {
    return {
      aeatModelNumber: "179",
      aeatModelType: "A0",
      aeatModelVersion: "1.0",
      selectedClient: null,
      reportDatesRange: {
        from: null,
        to: null,
      },
    };
  },
  computed: {
    loading() {
      return this.$store.getters["bookings/loading"];
    },
    reports() {
      return this.$store.getters["bookings/unsentAeat179Reports"];
    },
    reportsWithError() {
      return this.reports.filter((booking) => {
        if (!booking.client?.nationality) return true;

        const idNumber = booking.client?.idNumber || null;
        if (!idNumber) return true;
        // With this condition we check if an email address has been introduced as idNumber
        return typeof idNumber === "string" && idNumber.includes("@");
      });
    },
    hasReportsWithError() {
      return !this.loading && this.reportsWithError.length > 0;
    },
    aeat179XmlText() {
      const aeat179CabeceraText = () => {
        return `<dec1:Cabecera>
          <dec1:TipoComunicacion>${this.aeatModelType}</dec1:TipoComunicacion>
          <dec1:Modelo>${this.aeatModelNumber}</dec1:Modelo>
          <dec1:Periodo>
            <dec1:Ejercicio>${this.selectedYear}</dec1:Ejercicio>
            <dec1:Periodo>${this.selectedTrimester}</dec1:Periodo>
          </dec1:Periodo>
          <dec1:IDVersionModelo>${this.aeatModelVersion}</dec1:IDVersionModelo>
          <dec1:IDDeclarante>
            <dec1:NIF>${AGENCY_CIF}</dec1:NIF>
            <dec1:NombreRazon>${AGENCY_LEGAL_NAME}</dec1:NombreRazon>
            <dec1:PersonaContacto>
              <dec1:Telefono>${AGENCY_PHONE}</dec1:Telefono>
              <dec1:ApellidosNombre>${AGENCY_CONTACT_NAME}</dec1:ApellidosNombre>
            </dec1:PersonaContacto>
          </dec1:IDDeclarante>
        </dec1:Cabecera>`;
      };

      const aeat179DeclaradosText = () => {
        const text = [];
        this.reports.forEach((booking) => {
          const declaradoText = aeat179DeclaradoText(booking);
          if (declaradoText) text.push(declaradoText);
        });
        return text.length ? text.join("") : "¡¡¡SENSE DECLARADOS!!!";
      };

      const aeat179OwnerIdText = (owner) => {
        if (!owner.idType) return "¡¡¡SENSE TIPUS DE DOCUMENT!!!";
        if (!owner.idNumber) return "¡¡¡SENSE NUMERO DE DOCUMENT!!!";
        switch (owner.idType) {
          case "DNI":
          case "NIF":
          case "CIF":
            return `<dec1:NIF>${owner.idNumber || ""}</dec1:NIF>`;
          default:
            return `<dec1:IDOtro><dec1:CodigoPais>${
              owner.nationality || ""
            }</dec1:CodigoPais><dec1:IDType>06</dec1:IDType><dec1:ID>${
              owner.idNumber || ""
            }</dec1:ID></dec1:IDOtro>`;
        }
      };

      const aeat179OwnerText = (owner) => {
        if (!owner) return "¡¡¡SENSE PROPIETARI!!!";
        return `<dec1:IDDeclarado>
          <dec1:Clave>T</dec1:Clave>
          <dec1:NombreRazon>${owner.fullName || ""}</dec1:NombreRazon>
          ${aeat179OwnerIdText(owner)}
        </dec1:IDDeclarado>`;
      };

      const aeat179IDDeclaradosText = (booking) => {
        const contract = booking?.contract || null;
        if (!contract) return "¡¡¡SENSE CONTRACTE!!!";

        const text = [];

        const owner = contract.owner;
        if (owner) {
          text.push(aeat179OwnerText(owner));
        }

        const coOwners = contract.coOwners;
        if (coOwners.length) {
          coOwners.forEach((coOwner) => text.push(aeat179OwnerText(coOwner)));
        }

        return text.length ? text.join("") : "¡¡¡SENSE PROPIETARIS!!!";
      };

      const getAddressTypeName = (code) => {
        if (!code) return null;
        switch (code) {
          case "STREET":
            return "CALLE";
          case "AVENUE":
            return "AVDA";
          case "PATH":
          case "PATHWAY":
            return "CAMIN";
          case "ROAD":
            return "CARRE";
          case "DISSEMINATED":
            return "DISEM";
          case "WAY":
            return "VIA";
          case "FREEWAY":
            return "AUTOV";
          case "SQUARE":
            return "PLAZA";
          case "COURT":
            return "PATIO";
          case "PASSAGE":
            return "PASAG";
          case "PROMENADE":
            return "PASEO";
          case "RAMBLA":
            return "RAMBL";
          case "OTHER":
          default:
            return "OTR";
        }
      };

      const getAddressName = (location) => {
        if (!location) return null;
        switch (location.addressType) {
          case "STREET":
          case "AVENUE":
          case "PATH":
          case "PATHWAY":
          case "ROAD":
          case "WAY":
          case "FREEWAY":
          case "SQUARE":
          case "COURT":
          case "PASSAGE":
          case "PROMENADE":
          case "RAMBLA":
            return location.addressName?.trim() ?? "";
          case "DISSEMINATED":
            return `Polígono ${location.addressPolygon} Parcela ${location.addressPlot}`;
          case "OTHER":
          default:
            return "";
        }
      };

      const getIdTypeCode = (nationality) => {
        if (!nationality || nationality !== "ES") return "06";
        return "03";
      };

      const aeat179DeclaradoText = (booking) => {
        if (!booking) return null;

        const localizator = booking.localizator || "";
        const client = booking.client || null;
        const clientName = client?.fullName?.trim() ?? "";
        const clientIdNumber = client?.idNumber?.trim() ?? "";
        const clientLocation = client?.location || null;
        const clientNationality =
          client?.nationality ?? (clientLocation?.country || "");
        const accommodation = booking.accommodation || null;
        const cadastralReference =
          accommodation?.cadastralReference?.trim() ?? "";
        const location = accommodation?.location || null;
        const zip = location?.zip?.trim() || null;
        const provinceCode = zip?.substring(0, 2) || "";
        const cityCode = zip?.substring(2) || "";
        const addressType = getAddressTypeName(location?.addressType) || "";
        const addressName = getAddressName(location) ?? "";
        const addressNumberType = location?.addressNumber ? "NUM" : "S/N";
        const addressNumber = location?.addressNumber?.trim() ?? "";
        const checkin = formatDateStringToAeatDate(booking.checkin) || "";
        const nights = booking.nights || "";
        const price = (booking.ratesPrice / 100).toFixed(2);

        return `<dec1:Declarado>
          <dec1:IDRegistroDeclarado>${localizator}</dec1:IDRegistroDeclarado>
          ${aeat179IDDeclaradosText(booking)}
          <dec1:Detalle>
            <dec1:IDCesionario>
              <dec1:NombreRazon>${clientName}</dec1:NombreRazon>
              <dec1:IDOtro>
                <dec1:CodigoPais>${clientNationality}</dec1:CodigoPais>
                <dec1:IDType>${getIdTypeCode(clientNationality)}</dec1:IDType>
                <dec1:ID>${clientIdNumber}</dec1:ID>
              </dec1:IDOtro>
            </dec1:IDCesionario>
            <dec1:IDInmueble>
              <dec1:SituacionInmueble>01</dec1:SituacionInmueble>
              <dec1:DatosInmueble>
                <dec1:ReferenciaCatastral>${cadastralReference}</dec1:ReferenciaCatastral>
                <dec1:Direccion>
                  <dec1:CodigoMunicipio>${cityCode}</dec1:CodigoMunicipio>
                  <dec1:CodigoProvincia>${provinceCode}</dec1:CodigoProvincia>
                  <dec1:TipoVia>${addressType}</dec1:TipoVia>
                  <dec1:NombreVia>${addressName}</dec1:NombreVia>
                  <dec1:TipoNumeracion>${addressNumberType}</dec1:TipoNumeracion>
                  <dec1:NumeroVivienda>${addressNumber}</dec1:NumeroVivienda>
                </dec1:Direccion>
              </dec1:DatosInmueble>
            </dec1:IDInmueble>
            <dec1:DesgloseOperacion>
              <dec1:PeriodoCesion>
                <dec1:FechaInicioCesion>${checkin}</dec1:FechaInicioCesion>
                <dec1:NumeroDiasDisfrute>${nights}</dec1:NumeroDiasDisfrute>
              </dec1:PeriodoCesion>
              <dec1:Importe>${price}</dec1:Importe>
            </dec1:DesgloseOperacion>
          </dec1:Detalle>
        </dec1:Declarado>`;
      };

      return `<?xml version="1.0" encoding="UTF-8"?>
      <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:dec="https://www2.agenciatributaria.gob.es/static_files/common/internet/dep/aplicaciones/es/aeat/ddii/enol/ws/Declaracion.xsd" xmlns:dec1="https://www2.agenciatributaria.gob.es/static_files/common/internet/dep/aplicaciones/es/aeat/ddii/enol/ws/DeclaracionInformativa.xsd">
        <soapenv:Header/>
        <soapenv:Body>
          <dec:Declaracion>
            ${aeat179CabeceraText()}
            ${aeat179DeclaradosText()}
          </dec:Declaracion>
        </soapenv:Body>
      </soapenv:Envelope>`;
    },
    selectedYear() {
      const splittedSelectedFromDate = this.reportDatesRange.from.split("-");
      return splittedSelectedFromDate[0];
    },
    selectedTrimester() {
      const selectedMonth = parseInt(this.reportDatesRange.from.split("-")[1]);
      if (selectedMonth >= 1 && selectedMonth <= 3) return "1T";
      if (selectedMonth >= 4 && selectedMonth <= 6) return "2T";
      if (selectedMonth >= 7 && selectedMonth <= 9) return "3T";
      return "4T";
    },
    canSearch() {
      return (
        !this.loading &&
        !!this.reportDatesRange.from &&
        !!this.reportDatesRange.to
      );
    },
  },
  methods: {
    fetchUnsentAeat179Reports() {
      return this.$store
        .dispatch("bookings/fetchBookingsWithUnsentAeat179Report", {
          startDate: this.reportDatesRange.from,
          endDate: this.reportDatesRange.to,
        })
        .catch(() => {
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          );
        });
    },
    generateReport() {
      const blob = new Blob([this.aeat179XmlText], {
        type: "text/plain;charset=utf-8",
      });
      const fileName = `XML179.${this.selectedYear}.${this.selectedTrimester}.xml`;
      saveAs(blob, fileName);
    },
    onItemSelected(client) {
      this.selectedClient = client;
      this.$bvModal.show("add-client-details-modal");
    },
    onClientSaved(clientDetails) {
      this.$store.dispatch(
        "bookings/updateUnsentAeat179ReportClient",
        clientDetails
      );
      this.$bvModal.hide("add-client-details-modal");
    },
    onSaveClientError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar actualitzar el client"
      );
      this.$bvModal.hide("add-client-details-modal");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
