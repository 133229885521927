<template>
  <b-overlay variant="white" rounded="sm" opacity="0.9" :show="loading">
    <b-form @submit.prevent="save">
      <b-row>
        <!-- first name -->
        <b-col cols="12">
          <b-form-group label="Nom" label-for="first-name">
            <b-form-input
              id="first-name"
              v-model="firstName"
              placeholder="Nom"
            />
          </b-form-group>
        </b-col>

        <!-- last name -->
        <b-col cols="12">
          <b-form-group label="Cognoms" label-for="last-name">
            <b-form-input
              id="last-name"
              v-model="lastName"
              placeholder="Cognoms"
            />
          </b-form-group>
        </b-col>

        <!-- id type -->
        <b-col cols="12">
          <b-form-group label="Tipus de document" label-for="id-type">
            <b-form-radio-group
              id="id-type"
              v-model="idType"
              :options="idTypeOptions"
              text-field="label"
            />
          </b-form-group>
        </b-col>

        <!-- id number -->
        <b-col cols="12">
          <b-form-group label="Nº document" label-for="id-number">
            <b-form-input
              id="id-number"
              v-model="idNumber"
              placeholder="Nº document"
            />
          </b-form-group>
        </b-col>

        <!-- nacionalitat -->
        <b-col cols="12">
          <b-form-group label="Nacionalitat" label-for="nationality">
            <v-select
              id="nationality"
              v-model="nationality"
              placeholder="Nacionalitat"
              :options="countryOptions"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <b-button type="submit" variant="primary"> Guarda </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
} from "bootstrap-vue";
import { idTypeOptions, countryOptions } from "@/utils/select-options";
import vSelect from "vue-select";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    vSelect,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      firstName: null,
      lastName: null,
      idType: null,
      idNumber: null,
      nationality: null,
      countryOptions,
    };
  },
  computed: {
    idTypeOptions() {
      return idTypeOptions.filter((option) => option.value !== "CIF");
    },
  },
  created() {
    this.initFormFields();
  },
  methods: {
    initFormFields() {
      if (!this.client) return;
      this.firstName = this.client.firstName || null;
      this.lastName = this.client.lastName || null;
      this.idType = this.client.idType || null;
      this.idNumber = this.client.idNumber || null;
      this.nationality = this.client.nationality || null;
    },
    save() {
      this.loading = true;
      this.$store
        .dispatch("booking/updateClient", {
          uuid: this.client.uuid,
          firstName: this.firstName,
          lastName: this.lastName,
          idType: this.idType,
          idNumber: this.idNumber,
          nationality: this.nationality,
        })
        .then((response) => this.$emit("saved", response.data))
        .catch(() => this.$emit("error"))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
