<template>
  <b-table
    striped
    borderless
    hover
    responsive
    class="position-relative"
    :busy="loading"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    sort-icon-left
    sticky-header
    no-border-collapse
    :items="mappedItems"
    show-empty
    :empty-text="$t('errors.emptyTable.title')"
    :empty-filtered-text="$t('errors.emptyTable.title')"
    :fields="fields"
    @row-clicked="onRowClicked"
  >
    <template #table-busy>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle mr-2" />
        <strong>{{ $t("Carregant") }}...</strong>
      </div>
    </template>

    <!-- Column: localizator -->
    <template #cell(localizator)="data">
      <b-booking-localizator
        :localizator="data.value"
        :source="data.item.source"
        :highlighted="data.item.highlighted"
        :cancelled="data.item.cancelled"
        :fictitious="data.item.fictitious"
      />
    </template>

    <!-- Column: checkin -->
    <template #cell(checkin)="data">
      {{ bookingFormattedDate(data.item.checkin) }}
    </template>

    <!-- Column: checkout -->
    <template #cell(checkout)="data">
      {{ bookingFormattedDate(data.item.checkout) }}
    </template>

    <!-- Column: clientIdNumber -->
    <template #cell(clientIdNumber)="data">
      <b-badge v-if="!data.value" variant="danger">
        {{ $t("No definit") }}
      </b-badge>
      <span v-else>
        {{ data.value }}
      </span>
    </template>
  </b-table>
</template>

<script>
import { BTable, BSpinner, BBadge } from "bootstrap-vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import { formatDateObjectToDate } from "@/utils/formatters";

export default {
  components: {
    BTable,
    BSpinner,
    BBookingLocalizator,
    BBadge,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: "date",
      sortDesc: true,
      fields: [
        { key: "localizator", label: this.$t("Nº reserva") },
        { key: "accommodation", label: this.$t("Allotjament") },
        { key: "checkin", label: this.$t("Entrada"), sortable: true },
        { key: "checkout", label: this.$t("Sortida"), sortable: true },
        { key: "clientName", label: this.$t("Client") },
        { key: "clientIdNumber", label: this.$t("Nº document") },
      ],
    };
  },
  computed: {
    mappedItems() {
      if (!this.items.length) return [];
      return this.items.map((booking) => ({
        uuid: booking.uuid || this.$t("No definit"),
        localizator: booking.localizator || this.$t("No definit"),
        status: booking.status || this.$t("No definit"),
        cancelled: booking.cancelled || false,
        source: booking.source || this.$t("No definit"),
        client: booking.client || null,
        clientName: booking.client?.fullName || this.$t("No definit"),
        clientIdNumber: booking.client?.idNumber || null,
        accommodation: booking.accommodation?.name || this.$t("No definit"),
        checkin: new Date(booking.checkin) || this.$t("No definit"),
        checkout: new Date(booking.checkout) || this.$t("No definit"),
        highlighted: booking.highlighted || false,
        fictitious: booking.fictitious || false,
      }));
    },
  },
  methods: {
    onRowClicked(booking) {
      const client = booking?.client || null;
      this.$emit("item-selected", client);
    },
    bookingFormattedDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };
      return (
        formatDateObjectToDate(date, this.$i18n.locale, formatting) ||
        "No definit"
      );
    },
  },
};
</script>

<style></style>
